import type { Customer, FieldError } from '@/types/shopify'

export interface CustomerUpdateQuery {
  customerUpdate: {
    customer: Customer
    customerUserErrors: FieldError[]
  }
}

export const CustomerUpdate = `mutation customerUpdate($input: CustomerUpdateInput!, $customerAccessToken: String!) {
  customerUpdate(customer: $input, customerAccessToken: $customerAccessToken) {
    customer {
      id
      firstName
      lastName
      email
      phone
    }
    customerUserErrors {
      field
      message
    }
  }
}`
