import { AddressFields } from '../fragments'
import type { Customer } from '@/types/shopify'

export interface GetCustomerQuery {
  customer: Customer
}

export const GetCustomer = `query getCustomer($token: String!) {
  customer(customerAccessToken: $token) {
    id
    firstName
    lastName
    acceptsMarketing
    email
    phone
    tags
    numberOfOrders
    createdAt
    updatedAt
    defaultAddress {
      ${AddressFields}
    }
  }
}`
