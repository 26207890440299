import type { CustomerAuthenticationResult } from '@/types/shopify'

const AccessTokenFragment = `customerAccessToken {
  accessToken
  expiresAt
}`

const CustomerUserErrorsFragment = `customerUserErrors {
  field
  message
}`

export interface CustomerAccessTokenCreateQuery {
  customerAccessTokenCreate: CustomerAuthenticationResult
}

export interface CustomerAccessTokenCreateWithMultipassQuery {
  customerAccessTokenCreateWithMultipass: CustomerAuthenticationResult
}

export interface CustomerAccessTokenRenewQuery {
  customerAccessTokenRenew: CustomerAuthenticationResult
}

export const CustomerAccessTokenCreate = `mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
  customerAccessTokenCreate(input: $input) {
    ${AccessTokenFragment}
    ${CustomerUserErrorsFragment}
  }
}`

export const CustomerAccessTokenCreateWithMultipass = `mutation customerAccessTokenCreateWithMultipass($token: String!) {
  customerAccessTokenCreateWithMultipass(multipassToken: $token) {
    ${AccessTokenFragment}
    ${CustomerUserErrorsFragment}
  }
}`

export const CustomerAccessTokenRenew = `mutation customerAccessTokenRenew($customerAccessToken: String!) {
  customerAccessTokenRenew(customerAccessToken: $customerAccessToken) {
    ${AccessTokenFragment}
    customerUserErrors: userErrors {
      field
      message
    }
  }
}`
