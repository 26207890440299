import { ImageFragment, ProductFragment } from '../fragments'
import type { GraphqlConnection } from '@/types/graphql'
import type { ProductEdge, PortalBannerMeta } from '@/types/shopify'

type BooleanString = 'true' | 'false'

export interface GetPortalSettingsQuery {
	spoon: ProductEdge
  blenderBottle: ProductEdge
  shakerBottle: ProductEdge
  settings: {
    enableFavorites: {
      value: BooleanString
    }
    enableReferrals: {
      value: BooleanString
    }
		enableCoffee: {
			value: BooleanString
		}
    bannerAutoplay: {
      value: BooleanString
    }
    banners: {
      references: GraphqlConnection<PortalBannerMeta>
    }
  }
}

export const GetPortalSettings = `query getPortalSettings {
  blenderBottle: product(handle: "subscriber-blenderbottle") {
		${ ProductFragment(2) }
	}
	shakerBottle: product(handle: "oasis-bottle") {
		${ ProductFragment(1) }
	}
	spoon: product(handle: "subscriber-spoon") {
		${ ProductFragment(1) }
	}
	settings: metaobject(
		handle: { type: "portal_settings", handle: "settings" }
	) {
    enableFavorites: field(key: "enable_favorites") {
      value
    }
    enableReferrals: field(key: "enable_referrals") {
      value
    }
		enableCoffee: field(key: "enable_coffee") {
      value
    }
    bannerAutoplay: field(key: "banner_autoplay") {
      value
    }
		banners: field(key: "banner_slides") {
			references(first: 10) {
				edges {
					node {
						... on Metaobject {
							id
							handle
							fields {
								key
								type
								value
								reference {
									... on Product {
										${ ProductFragment(1) }
									}
									... on MediaImage {
                    alt
										image {
                      ${ ImageFragment(2000) }
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}`
