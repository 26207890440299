import type { Customer, FieldError } from '@/types/shopify'

export interface CustomerCreateQuery {
  customerCreate: {
    customer?: Customer
    customerUserErrors: FieldError[]
  }
}

export const CustomerCreate = `mutation customerCreate($input: CustomerCreateInput!) {
  customerCreate(input: $input) {
    customer {
      id
      firstName
      lastName
      email
      phone
    }
    customerUserErrors {
      field
      message
    }
  }
}`
