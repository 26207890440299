export const AddressFields = `firstName
lastName
address1
address2
city
province
provinceCode
zip
country
countryCode`
