<template>
  <!-- Favicon -->
  <Link
    rel="icon"
    href="/favicon.ico"
  />
  <Link
    rel="icon"
    type="image/svg+xml"
    href="/icons/icon.svg"
  />
  <Link
    rel="icon"
    type="image/png"
    href="/favicon-32x32.png"
    sizes="32x32"
  />
  <Link
    rel="icon"
    type="image/png"
    href="/favicon-16x16.png"
    sizes="16x16"
  />

  <!-- Apple Touch Icon -->
  <Link
    rel="apple-touch-icon"
    href="/apple-touch-icon.png"
  />
  <Link
    rel="apple-touch-icon"
    href="/apple-touch-icon-152.png"
    sizes="152x152"
  />
  <Link
    rel="apple-touch-icon"
    href="/apple-touch-icon-167.png"
    sizes="167x167"
  />
  <Link
    rel="apple-touch-icon"
    href="/apple-touch-icon-180.png"
    sizes="180x180"
  />

  <!-- Apple Launch Screens -->
  <!-- iPhone 15 Pro Max, 14 Pro Max (430x932px) -->
  <Link
    rel="apple-touch-startup-image"
    media="(device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3)"
    href="/apple-launch-1290x2796.png"
  />
  <!-- iPhone 15 Plus, 14 Plus, 13 Pro Max (428x926px) -->
  <Link
    rel="apple-touch-startup-image"
    media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3)"
    href="/apple-launch-1284x2778.png"
  />
  <!-- iPhone 15 Pro, 14 Pro (393x852px) -->
  <Link
    rel="apple-touch-startup-image"
    media="(device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3)"
    href="/apple-launch-1179x2556.png"
  />
  <!-- iPhone 14, 13 Pro (390x844px) -->
  <Link
    rel="apple-touch-startup-image"
    media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)"
    href="/apple-launch-1170x2532.png"
  />
  <!-- iPhone 11 Pro Max, Xs Max (414x896px dpi 3) -->
  <Link
    rel="apple-touch-startup-image"
    media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
    href="/apple-launch-1242x2688.png"
  />
  <!-- iPhone Xr (414x896px dpi 2) -->
  <Link
    rel="apple-touch-startup-image"
    media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
    href="/apple-launch-828x1792.png"
  />
  <!-- iPhone 13 mini, X, Xs (375x812px) -->
  <Link
    rel="apple-touch-startup-image"
    media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
    href="/apple-launch-1125x2436.png"
  />
  <!-- iPhone 8 Plus, 7 Plus, 6s Plus, 6 Plus (414x736px) -->
  <Link
    rel="apple-touch-startup-image"
    media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)"
    href="/apple-launch-1242x2208.png"
  />
  <!-- iPhone SE, 8, 7, 6s, 6 (750px x 1334px) -->
  <Link
    rel="apple-touch-startup-image"
    media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
    href="/apple-launch-750x1334.png"
  />
  <!-- iPad Pro 12.9" (2048px x 2732px) -->
  <Link
    rel="apple-touch-startup-image"
    media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)"
    href="/apple-launch-2048x2732.png"
  />
  <!-- iPad Pro 11” (1668px x 2388px) -->
  <Link
    rel="apple-touch-startup-image"
    media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)"
    href="/apple-launch-1668x2388.png"
  />
  <!-- iPad Pro 10.5" (1668px x 2224px) -->
  <Link
    rel="apple-touch-startup-image"
    media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)"
    href="/apple-launch-1668x2224.png"
  />
  <!-- iPad Mini, Air (1536px x 2048px) -->
  <Link
    rel="apple-touch-startup-image"
    media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)"
    href="/apple-launch-1536x2048.png"
  />

  <!-- Other -->
  <Link
    rel="mask-icon"
    href="/safari-pinned-tab.svg"
    color="#aaaaaa"
  />
  <Meta
    name="application-name"
    content="Oats Overnight"
  />
  <Meta
    name="description"
    content="Oats Overnight"
  />
  <Meta
    name="apple-mobile-web-app-title"
    content="Oats Overnight"
  />
  <Meta
    name="apple-mobile-web-app-capable"
    content="yes"
  />
  <Meta
    name="mobile-web-app-capable"
    content="yes"
  />
  <Meta
    name="apple-touch-fullscreen"
    content="yes"
  />
  <Meta
    name="msapplication-TileColor"
    content="#ffffff"
  />
  <Meta
    name="theme-color"
    content="#ffffff"
  />
  <Meta
    name="app-context"
    content="hosted"
  />
</template>
