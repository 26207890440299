import { useAuthStore } from '@/stores'

const log = logger('auth-middleware')

const decodeUrlTokenParams = (url: string) => {
  try {
    const token = new URLSearchParams(url).get('token')
    const decoded = token && atob(token)
    return decoded ? new URLSearchParams(decoded) : undefined
  } catch (e) {
    console.warn(e)
  }
}

const getCheckoutUrl = (url: string) => decodeUrlTokenParams(url)?.get('checkout_url') || undefined

export default defineNuxtRouteMiddleware(async (to) => {
  const { path, fullPath, meta: { publicPath, noRedirect } } = to

  const returnTo = useState<string | undefined>('return_to')
  const checkoutUrl = useState<string | undefined>('checkout_url')

  if (noRedirect || path.startsWith('/admin')) {
    return
  }

  const auth = useAuthStore()
  const { logout, authenticated, shopify: { customerAccessToken } } = auth

  const checkoutUrlFromToken = getCheckoutUrl(fullPath)
  if (checkoutUrlFromToken) {
    checkoutUrl.value = checkoutUrlFromToken
  }

  log('path:', path, 'authenticated?', authenticated, 'public?', publicPath)

  if (path === '/logout') {
    logout()
    return navigateTo('/login')
  }

  if (authenticated && customerAccessToken && checkoutUrl.value) {
    const token = await fetchMultipass('shopify', customerAccessToken)
    const url = shopifyMultipassUrl(token, checkoutUrl.value)
    checkoutUrl.value = undefined // reset url
    return navigateTo(url, { external: true })
  }

  if (authenticated) {
    if (returnTo.value) {
      const newPath = returnTo.value
      returnTo.value = undefined
      return navigateTo(newPath)
    } else if (path === '/login') {
      return navigateTo(fullPath.replace('/login', '/'))
    }
  }

  if (!authenticated && !publicPath) {
    returnTo.value = fullPath
    return navigateTo('/login')
  }
})
