<template>
  <div class="fixed inset-0 bg-neutral-50 flex flex-col gap-4 items-center z-50">
    <div class="flex flex-col h-full w-full bg-white pb-8 sm:max-w-[393px] sm:max-h-[852px] sm:my-8 sm:rounded-lg sm:shadow-sm">
      <div class="flex flex-col w-full h-full">
        <Splide
          :options="options"
          class="pagination-dots h-full"
          :has-track="false"
        >
          <SplideTrack class="flex flex-col h-full sm:rounded-t-lg">
            <SplideSlide>
              <WelcomeStepOne />
            </SplideSlide>
            <SplideSlide>
              <WelcomeStepTwo />
            </SplideSlide>
            <SplideSlide>
              <WelcomeStepThree />
            </SplideSlide>
            <SplideSlide>
              <WelcomeStepFour />
            </SplideSlide>
          </SplideTrack>
        </Splide>
      </div>
      <div class="flex flex-col gap-2 w-full px-4 shrink-0">
        <button
          class="button +white w-full"
          @click="signup"
        >
          Create an Account
        </button>
        <button
          class="button +green w-full"
          @click="login"
        >
          Log in
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Splide, SplideSlide,SplideTrack, type Options } from '@splidejs/vue-splide'
const app = useAppStore()
const navigate = async (path: string) => {
  await navigateTo(path)
  app.showWelcomeScreen = false
}
const login = async () => await navigate('/login')
const signup = async () => await navigate('/signup')
const options: Options = {
  arrows: false
}
</script>
