import { ref } from 'vue'
import { defineStore } from 'pinia'
import { SupabaseClient } from '@supabase/supabase-js'

import { until } from '@/utils/timer'
import { gidToId } from '@/utils/graphql'
import { SUPABASE_ANON_KEY, supabaseClient, oatbizClient } from '@/services/supabase'

import type { AxiosError, AxiosInstance } from 'axios'
import type { Database } from '@/types/supabase'

interface JWTResponse {
  signed: string
}

export const useAuthStore = defineStore('supabase/auth', () => {
  const adminAuth = useAdminStore()

  const authenticated = ref(false)
  const _oatbiz = ref<AxiosInstance>()
  const _supabase = ref<SupabaseClient<Database>>()
  const _customerId = ref<number>()

  const useSupabase = async () => {
    await until(() => authenticated.value)
    const oatbiz = _oatbiz.value
    const supabase = _supabase.value
    const customerId = _customerId.value
    if (oatbiz && supabase && customerId) {
      return {
        oatbiz,
        supabase,
        customerId
      }
    }
    throw new Error('Supabase client did not init.')
  }

  const initOatbiz = async (id: number) => {
    // init client
    const client = oatbizClient()
    // resign JWT with auth token
    const { data: { signed } } = await fetchWithRetry(() => client.post<JWTResponse>('/jwt', {
      jwt: SUPABASE_ANON_KEY,
      data: {
        customer: {
          id
        },
        admin: adminAuth.jwt
      }
    }))
    // add request interceptor with enhanced JWT
    client.interceptors.request.use((request) => {
      request.headers.set('Authorization', `Bearer ${signed}`)
      return request
    }, (error: AxiosError) => Promise.reject(error))
    // assign a client to ref
    _oatbiz.value = client
    return { client, jwt: signed }
  }

  const initSupabaseClient = (jwt: string) => {
    _supabase.value = supabaseClient({
      global: {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    })
  }

  const authenticate = async (shopifyCustomerId: string) => {
    try {
      const id = _customerId.value = gidToId(shopifyCustomerId)
      const { jwt } = await initOatbiz(id)

      // edge case when visiting /admin while logged in and logout is called.
      // we no longer need to init supabase client so return
      if (!_customerId.value) {
        log('auth changed, abort init')
        return false
      }

      initSupabaseClient(jwt)
      log('auth complete')
      return authenticated.value = true
    } catch (e) {
      log('authentication failed')
      sendToSentry(e)
    }
  }

  const reset = () => {
    _supabase.value = _oatbiz.value = _customerId.value = undefined
    authenticated.value = false
  }

  return {
    useSupabase,
    initOatbiz,
    authenticate,
    authenticated,
    reset
  }
})
