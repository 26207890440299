export const isNativeApp = window.navigator.userAgent.includes('com.oatsovernight.app.portal')

export const clearCaches = async () => {
  if (!window.caches) {
    return
  }
  const keys = await caches.keys()
  for (const key of keys) {
    await caches.delete(key)
  }
}
