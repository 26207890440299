import { useAuthStore, useAdminStore } from '@/stores'

const log = logger('admin-middleware')

export default defineNuxtRouteMiddleware(async ({ name, path, fullPath }) => {
  if (!path.startsWith('/admin')) {
    return
  }

  const admin = useAdminStore()
  const returnTo = useState<string | undefined>('return_to')

  log('authenticated?', path, admin.authenticated)

  if (admin.authenticated) {
    const redirect = returnTo.value
    if (redirect) {
      returnTo.value = undefined
      return navigateTo(redirect)
    }
    return true
  } else if (name !== 'admin-login') {
    returnTo.value = fullPath
    const { logout } = useAuthStore()
    logout()
    return navigateTo('/admin/login')
  }
})
