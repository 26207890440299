import { getActivePinia, type Pinia, type Store } from 'pinia'

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>
}

export const resetPinia = () => {
  const pinia = getActivePinia() as ExtendedPinia
  pinia._s.forEach((store) => {
    if (store.$id === 'admin') {
      return
    }
    if ('reset' in store && typeof store.reset === 'function') {
      store.reset()
      log('🍍', JSON.stringify(store.$id), 'store reset')
    }
  })
}
