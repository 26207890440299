<template>
  <Teleport to="body">
    <div class="fixed bottom-0 left-0 right-0 p-1 z-[99]">
      <TransitionGroup
        name="toast"
        tag="ul"
        class="relative flex flex-col gap-3 items-stretch"
      >
        <li
          v-for="toast in store.messages"
          :key="toast.id"
          class="toast-message text-white text-center p-3 rounded-lg"
          :class="toast.type"
        >
          {{ toast.message }}
        </li>
      </TransitionGroup>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { useToastStore } from '@/stores'
const store = useToastStore()
</script>
