<template>
  <div class="flex-center h-full">
    <div class="p-8 text-gray-500">
      <h1 class="text-3xl">
        Oh No!
      </h1>
      <h2 class="text-2xl">
        Something went wrong, please try again.
      </h2>
      <p class="mt-5">
        <ErrorReloadButton />
      </p>
    </div>
  </div>
</template>
