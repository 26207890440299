import type { FieldError } from '@/types/shopify'

export interface CustomerRecoverQuery {
  customerRecover: {
    customerUserErrors: FieldError[]
  }
}

export const CustomerRecover = `mutation customerRecover($email: String!) {
  customerRecover(email: $email) {
    customerUserErrors {
      field
      message
    }
  }
}`
