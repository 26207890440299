import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { formatISO, subDays } from 'date-fns'

import { useAuthStore } from './auth'
import { useBundleSelectionsStore } from './bundle-selections'

import { listCharges, processCharge } from '@rechargeapps/storefront-client'
import type { Charge } from '@rechargeapps/storefront-client'

interface LoadChargeOptions {
  purchase_item_id?: number
  bundles?: boolean
}

export const useChargeStore = defineStore('recharge/charge', () => {
  const { useSession } = useAuthStore()
  const { useBundleSelections, reset: resetSelections } = useBundleSelectionsStore()

  const loading = ref(false)
  const response = ref()
  const charges = computed<Charge[]>(() => response.value?.charges || [])

  const hasCharges = computed(() => charges.value.length > 0)
  const hasOneCharge = computed(() => charges.value.length === 1)
  const hasInvalidCharge = computed(() => charges.value.some(i => i.status === 'error'))

  const loadCharges = async (options: LoadChargeOptions = {}) => {
    try {
      loading.value = true
      response.value = await listCharges(useSession(), {
        purchase_item_id: options.purchase_item_id,
        scheduled_at_min: formatISO(subDays(new Date(), 7), { representation: 'date' }),
        status: [ 'queued', 'error', 'pending' ],
        sort_by: 'scheduled_at-asc'
      })
      if (options?.bundles) {
        const ids = charges.value.flatMap(i => i.line_items.map(i => i.purchase_item_id))
        resetSelections()
        useBundleSelections(ids)
      }
      loading.value = false
    } catch (e) {
      loading.value = false
      response.value = undefined
      sendToSentry(e)
    }
  }

  const removeLineItem = (id: number) => {
    charges.value?.forEach(charge => {
      const index = charge.line_items.findIndex(i => i.purchase_item_id === id)
      if (index >= 0) {
        charge.line_items.splice(index, 1)
      }
    })
  }

  const getLineItemCharge = (id: number) => charges.value.find(c => c.line_items.some(i => i.purchase_item_id === id))

  const useCharges = (options: LoadChargeOptions = {}) => {
    if (!response.value && !loading.value) {
      loadCharges(options)
    }
    return charges
  }

  const process = async (charge: Charge) => await processCharge(useSession(), charge.id)

  const reset = () => {
    response.value = undefined
    loading.value = false
  }

  return {
    loadCharges,
    removeLineItem,
    getLineItemCharge,
    useCharges,
    process,
    reset,
    loading,
    hasCharges,
    hasOneCharge,
    hasInvalidCharge,
    charges,
    response
  }
})
