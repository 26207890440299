<template>
  <WelcomeSlide>
    <template #image>
      <img
        src="@/assets/welcome/welcome-two.webp"
        alt="Oats Overnight"
        width="407"
        height="311"
        class="absolute translate-y-24"
      >
    </template>
    <template #title>
      You've got Access to Exclusive Flavors
    </template>
    <template #paragraph>
      There's 15+ flavors only available to subscribers. Enjoy them all.
    </template>
  </WelcomeSlide>
</template>
