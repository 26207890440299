const GRAPHQL_ENDPOINT = `https://${import.meta.env.VITE_SHOPIFY_URL}/api/2023-07/graphql.json`
export const STOREFRONT_ACCESS_TOKEN = import.meta.env.VITE_SHOPIFY_STOREFRONT_ACCESS_TOKEN

import type { FieldError } from '@/types/shopify'

interface QueryParams {
  query: string
  variables?: object
}

export const graphql = {
  async query<T> ({ query, variables }: QueryParams) {
    const response = await fetchWithRetry(() => fetch(GRAPHQL_ENDPOINT, {
      method: 'POST',
      headers: {
        'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: query.replace(/(?:\r\n|\r|\n|\s)+/g, ' '),
        variables
      })
    }))
    const { data, errors }: { data: T, errors?: FieldError[] } = await response.json()
    return {
      data: data || {} as T,
      errors
    }
  }
}
