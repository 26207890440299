import { ImageFragment, ProductMetaFieldsFragment, BundleParentProductFragment } from '../fragments'
import type { GraphqlConnection } from '@/types/graphql'
import type { Image, ProductVariant, BundleProductVariant } from '@/types/shopify'

export interface BundleParentProductEdge {
  id: string
  variants: GraphqlConnection<ProductVariant>
}

export interface BundleProductEdge {
  id: string
  title: string
  tags: string[]
  images: GraphqlConnection<Image>
  featuredImage: Image
  flavorForwardImage?: {
    value: string
    reference: Image
  }
  nutrition: {
    value: string
  }
  ratingAverage: {
    value: string
  }
  ratingCount: {
    value: string
  }
  protein: {
    value: string
  }
  calories: {
    value: string
  }
  variants: GraphqlConnection<BundleProductVariant>
}

export interface GetBundleProductsQuery {
  onetimeProduct: BundleParentProductEdge
  subscriptionProduct: BundleParentProductEdge
  collection: {
    products: GraphqlConnection<BundleProductEdge>
  }
}

export const GetBundleProducts = `query getBundleProducts($onetimeHandle: String!, $subscriptionHandle: String!, $collectionId: ID!) {
  onetimeProduct: product(handle: $onetimeHandle) {
    ${ BundleParentProductFragment }
  }
  subscriptionProduct: product(handle: $subscriptionHandle) {
    ${ BundleParentProductFragment }
  }
  collection(id: $collectionId) {
    products(first: 60) {
      edges {
        node {
          id
          handle
          title
          tags
          createdAt
          images(first: 4) {
            edges {
              node {
                ${ ImageFragment(720) }
              }
            }
          }
          featuredImage {
            ${ ImageFragment(640) }
          }
          ${ ProductMetaFieldsFragment }
          variants(first: 1) {
            edges {
              node {
                id
                sku
                price {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
      }
    }
  }
}`
