<template>
  <WelcomeSlide>
    <template #image>
      <img
        src="@/assets/welcome/welcome-three.webp"
        alt="Oats Overnight"
        width="451"
        height="308"
        class="absolute translate-x-3 translate-y-8"
      >
    </template>
    <template #title>
      Swap Flavors & Try New Ones
    </template>
    <template #paragraph>
      We launch a new flavor each month so there's always something new to try.
    </template>
  </WelcomeSlide>
</template>
