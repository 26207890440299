import type { MultipassProvider, MultipassProviderData } from '@/types'

export const fetchMultipass = async (provider: MultipassProvider, data: MultipassProviderData) => {
  const { token } = await $fetch<{ token: string }>('/api/auth/multipass', {
    method: 'POST',
    body: {
      provider,
      data
    }
  })
  return token
}
