<template>
  <WelcomeSlide>
    <template #image>
      <img
        src="@/assets/welcome/welcome-one.webp"
        alt="Oats Overnight"
        width="243"
        height="505"
        class="absolute translate-y-24"
      >
    </template>
    <template #title>
      Welcome to Oats Overnight
    </template>
    <template #paragraph>
      Manage your account, edit your subscription, & see what's new.
    </template>
  </WelcomeSlide>
</template>
