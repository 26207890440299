<template>
  <div class="flex flex-col items-center text-center h-full w-full">
    <div class="relative flex-center flex-col w-full grow bg-green-300">
      <slot name="image" />
    </div>
    <div class="flex flex-col gap-8 pb-10 h-64 pt-9 bg-white z-10">
      <h1 class="px-2 font-bold text-[40px] leading-[37px]">
        <slot name="title" />
      </h1>
      <p class="text-xl px-4 leading-6 tracking-[-0.5px]">
        <slot name="paragraph" />
      </p>
    </div>
  </div>
</template>
