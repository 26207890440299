import {
  ref,
  shallowRef,
  nextTick,
  defineAsyncComponent,
  type AsyncComponentLoader,
  type Component
} from 'vue'

export interface DynamicComponent {
  component: Component
  props: object
  events: Events
}

type DynamicComponentLoader = AsyncComponentLoader<Component | Promise<Component>>
type Events = {
  [key: string]: Function
}

export const useDynamicComponent = () => {

  const visible = ref(false)
  const stack = ref(false)
  const content = shallowRef<DynamicComponent>()
  let timeout: ReturnType<typeof setTimeout> | undefined

  const open = async (component: Component | DynamicComponentLoader, props: object = {}, events: Events = {}) => {
    if (timeout) {
      return
    }
    if (visible.value) {
      await close()
    }
    content.value = {
      component: '__name' in component ? component : defineAsyncComponent(component as DynamicComponentLoader),
      props,
      events
    }
    await nextTick()
    visible.value = true
  }

  const close = async () => {
    return new Promise<void>((resolve) => {
      if (content.value?.events?.close) {
        content.value?.events?.close()
      }
      visible.value = false
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        timeout = content.value = undefined
        resolve()
      }, 200)
    })
  }

  return {
    visible,
    stack,
    content,
    open,
    close
  }
}

export type DynamicContentAPI = ReturnType<typeof useDynamicComponent>
