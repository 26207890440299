import type { FieldError } from '@/types/shopify'

export interface ErrorWithMessage {
  error_description?: string
  message: string
}

export class AppError extends Error {
  override get message () {
    return 'Something went wrong, please try again.'
  }
}

export class AuthError extends Error {
  override get message () {
    return 'Login failed, please try again.'
  }
}

export class GraphqlError extends Error {}

export class CustomerError extends Error {
  override message: string
  fieldErrors: FieldError[]
  constructor (message: string, fieldErrors: FieldError[]) {
    super()
    this.message = message
    this.fieldErrors = fieldErrors
  }
}

export const isErrorWithMessage = (error: any): error is ErrorWithMessage => (
  typeof error === 'object' && 'message' in error
)
