export const timer = (ms: number) => new Promise(res => setTimeout(res, ms))

export const until = (condition: () => boolean) => {
  let stop: (() => void) | null = null
  const watcher = new Promise<boolean>((resolve) => {
    if (condition()) {
      return resolve(true)
    }
    stop = watch(
      condition,
      (value) => {
        if (value) {
          stop?.()
          resolve(value)
        }
      }
    )
  })
  return watcher
}
