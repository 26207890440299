<template>
  <button
    class="flex-center rounded-full p-1"
    data-gtm-action="close"
    aria-label="close"
  >
    <SvgIcon
      name="close"
      class="w-5 h-5"
    />
  </button>
</template>
