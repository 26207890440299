import axios from 'axios'
import { createClient } from '@supabase/supabase-js'
import type { SupabaseClient, SupabaseClientOptions } from '@supabase/supabase-js'
import type { Database } from '@/types/supabase'

export const SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL
export const SUPABASE_ANON_KEY = import.meta.env.VITE_SUPABASE_ANON_KEY
export const OATBIZ_API_HOST = import.meta.env.VITE_OATBIZ_API_HOST

export const oatbizClient = () => axios.create({ baseURL: OATBIZ_API_HOST })

export const supabaseClient = (options?: SupabaseClientOptions<'public'>) => {
  return createClient<Database>(SUPABASE_URL, SUPABASE_ANON_KEY, options) as SupabaseClient<Database>
}

export const loadVariantInventoryByProductId = async (productId: number) => {
  const { data, error } = await supabaseClient()
    .from('merchandise_available_inventory')
    .select('*')
    .eq('shopify_product_id', productId)

  if (error) {
    console.error('> Could not load product inventory')
    return []
  }
  return data
}

export const loadSkuMapping = async (sku: string) => {
  const { data, error } = await supabaseClient()
    .from('sku_products_show')
    .select('*')
    .eq('sku', sku)

  if (error) {
    console.error('> Could not load sku mapping')
    return null
  }
  return data
}
