import { ref, type Ref } from 'vue'

export const useLoading = <T extends Array<any>, U>(fn: (...args: T) => Promise<U>): [ Ref<boolean>, (...args: T) => Promise<U> ] => {
  const loading = ref(false)
  const wrapped = async (...args: T) => {
    loading.value = true
    const value = await fn(...args)
    loading.value = false
    return value
  }
  return [
    loading,
    wrapped
  ]
}

export const useNetworkToast = (): [ Ref<boolean>, Ref<boolean | undefined>, (outcome: boolean) => Promise<boolean> ] => {
  let timeout: ReturnType<typeof setTimeout> | undefined
  const loading = ref(false)
  const success = ref<boolean>()
  const networkResult = (outcome: boolean) => {
    loading.value = false
    success.value = outcome
    clearTimeout(timeout)
    return new Promise<boolean>((resolve) => {
      timeout = setTimeout(() => {
        loading.value = false
        success.value = undefined
        resolve(outcome)
      }, 1000)
    })
  }
  return [
    loading,
    success,
    networkResult
  ]
}
