import { ref } from 'vue'
import { defineStore } from 'pinia'

import type { Credentials } from '@/types'

export const useAdminStore = defineStore('admin', () => {
  const jwt = ref<string>()

  const authenticate = async (body: Credentials) => {
    const data = await $fetch<{ jwt: string }>('/api/auth/admin/session', {
      method: 'POST',
      body
    })
    jwt.value = data?.jwt
    if (jwt.value) {
      return true
    }

    throw new AuthError()
  }

  // TODO: more secure admin auth?
  const authenticated = computed(() => {
    try {
      return jwt.value && !!parseJwt(jwt.value)
    } catch (e) {
      sendToSentry(e)
      return false
    }
  })

  const setJwt = (token: string | undefined) => {
    jwt.value = token
  }

  const reset = () => {
    jwt.value = undefined
  }

  const useAdminHeaders = () => {
    return {
      authorization: `Bearer ${jwt.value}`
    }
  }

  return {
    jwt,
    authenticate,
    authenticated,
    useAdminHeaders,
    reset,
    setJwt
  }
}, {
  persist: {
    pick: [ 'jwt' ]
  }
})
