import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { graphql } from '@/services/shopify'
import { BUNDLE_COLLECTION_ID, COFFEE_BUNDLE_COLLECTION_ID } from '@/utils/constants'
import { gidToId, graphqlUnpack } from '@/utils/graphql'
import { GetBundleProducts, type GetBundleProductsQuery, type BundleProductEdge } from '@/graphql/queries'
import type { BundleSellingPlanGroup, BundleParentProduct, BundleParentProductVariant, BundleProduct, BundleProductVariant, Image } from '@/types/shopify'
import type { BundleSelectionProductItem } from '@/types/recharge'
import type { BundleSelectionItem } from '@rechargeapps/storefront-client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const unpackProduct = (product: any) => {
  const { id, variants: { edges } } = product
  const variants = graphqlUnpack<BundleParentProductVariant>(edges)
  const sellingPlanGroups = graphqlUnpack<BundleSellingPlanGroup>(product.sellingPlanGroups.edges)
  return {
    id,
    nid: gidToId(id),
    variants: variants.map(i => ({
      ...i,
      size: Number(String(i.title).replace(/[^\d]/g, ''))
    })),
    sellingPlanGroups
  } as BundleParentProduct
}

export const useBundleProductsStore = defineStore('shopify/bundle-products', () => {
  const products = ref<BundleProduct[]>()
  const onetimeProduct = ref<BundleParentProduct>()
  const subscriptionProduct = ref<BundleParentProduct>()

  const coffeeProducts = ref<BundleProduct[]>()
  const coffeeOnetimeProduct = ref<BundleParentProduct>()
  const coffeeSubscriptionProduct = ref<BundleParentProduct>()
  const loading = ref(false)

  const loadProducts = async (onetimeHandle: string, collectionId: string) => {
    const { data } = await graphql.query<GetBundleProductsQuery>({
      query: GetBundleProducts,
      variables: {
        onetimeHandle,
        subscriptionHandle: `${onetimeHandle}-subscription`,
        collectionId: `gid://shopify/Collection/${collectionId}`
      }
    })
    return {
      onetime: unpackProduct(data.onetimeProduct),
      subscription: unpackProduct(data.subscriptionProduct),
      collection: graphqlUnpack<BundleProductEdge>(data.collection.products.edges)
        .map(product => {
          const variants = graphqlUnpack<BundleProductVariant>(product.variants.edges)
          const sku = variants[0].sku
          return {
            ...product,
            sku,
            nid: gidToId(product.id),
            variants,
            images: graphqlUnpack<Image>(product.images.edges),
            flavorForwardImage: imageRef(product.flavorForwardImage)
          } as BundleProduct
        })
        .sort((a, b) => Number(b.tags.includes('new-flavor')) - Number(a.tags.includes('new-flavor')))
    }
  }

  const loadOatmeal = async () => {
    const { onetime, subscription, collection } = await loadProducts('custom-bundle', BUNDLE_COLLECTION_ID)
    onetimeProduct.value = onetime
    subscriptionProduct.value = subscription
    products.value = collection
  }

  const loadCoffee = async () => {
    const { onetime, subscription, collection } = await loadProducts('coffee-bundle', COFFEE_BUNDLE_COLLECTION_ID)
    coffeeOnetimeProduct.value = onetime
    coffeeSubscriptionProduct.value = subscription
    coffeeProducts.value = collection
  }

  const load = async () => {
    loading.value = true
    await Promise.all([
      loadOatmeal(),
      loadCoffee()
    ])
    loading.value = false
  }

  const allProducts = computed(() => [ ...(products.value ?? []), ...(coffeeProducts.value ?? []) ])

  const productMap = computed(() => {
    return (allProducts.value).reduce((acc, product) => {
      acc.set(product.sku, product)
      return acc
    }, new Map<string, BundleProduct>())
  })

  const findProductBySku = (sku: string) => {
    const product = allProducts.value.find(i => i.sku === sku)
    if (!product) {
      throw Error(`Product not found by sku: ${sku}`)
    }
    return product
  }

  const bundleProductItem = (id: number, quantity: number) => {
    const product = allProducts.value.find(i => i.nid === id)
    if (!product) {
      throw new Error(`Product not found for id: ${id}`)
    }
    const item: BundleSelectionProductItem = {
      product,
      quantity
    }
    return item
  }

  const bundleProductItems = (bundleItems: BundleSelectionItem[]) => {
    if (!allProducts.value?.length) {
      return []
    }

    return bundleItems.map(item => {
      const { id, external_product_id, quantity } = item
      const product = allProducts.value.find(product => product.nid === Number(external_product_id))
      if (!product) {
        throw new Error(`Could not find product for item ${item.external_product_id}`)
      }
      return {
        product,
        id,
        quantity
      }
    }).sort((a, b) => a.product.title.localeCompare(b.product.title))
      .sort((a, b) => b.quantity - a.quantity)
  }

  const reset = () => {
    loading.value = false
    // no need to reset non-user specific data
    // products / onetimeProduct / subscriptionProduct
  }

  return {
    bundleProductItem,
    bundleProductItems,
    productMap,
    findProductBySku,
    products,
    onetimeProduct,
    subscriptionProduct,
    coffeeProducts,
    coffeeSubscriptionProduct,
    coffeeOnetimeProduct,
    allProducts,
    loading,
    load,
    reset
  }
})
// , {
//   persist: {
//     // TODO: add some sort of TTL here
//     // https://prazdevs.github.io/pinia-plugin-persistedstate/guide/config.html#beforerestore
//     pick: [ 'products', 'onetimeProduct', 'subscriptionProduct' ],
//     storage: sessionStorage
//   }
// })
