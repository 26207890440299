import { initRecharge } from '@rechargeapps/storefront-client'
export * from '@rechargeapps/storefront-client'

import { useAuthStore } from '@/stores/auth'

const {
  VITE_SHOPIFY_URL,
  VITE_RECHARGE_STOREFRONT_TOKEN
} = import.meta.env

const TOKEN_STORAGE_KEY = 'rechargeTokenIndex'

export const getNextStorefrontToken = () => {
  const currentTokenIndex = Number(localStorage.getItem(TOKEN_STORAGE_KEY)) || 0
  const tokens = VITE_RECHARGE_STOREFRONT_TOKEN?.split(',')
  localStorage.setItem(TOKEN_STORAGE_KEY, String((currentTokenIndex + 1) % (tokens?.length || 1)))
  return tokens?.[currentTokenIndex]
}

export const createRecharge = () =>
  initRecharge({
    storeIdentifier:VITE_SHOPIFY_URL,
    storefrontAccessToken: getNextStorefrontToken(),
    loginRetryFn: async () => {
      const { refreshSession } = useAuthStore()
      const session = await refreshSession()
      if (session) {
        return session
      }
    }
  })


export const createRechargeServer = () =>
  initRecharge({
    storeIdentifier: VITE_SHOPIFY_URL,
    storefrontAccessToken: getNextStorefrontToken()
  })
