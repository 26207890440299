<template>
  <Dialog>
    <slot />
    <ModalDialog v-if="$app.modal.content" />
  </Dialog>
</template>

<script lang="ts">
import {
  Dialog
} from '@headlessui/vue'
export default {
  extends: Dialog
}
</script>

<script lang="ts" setup>
import {
  Dialog
} from '@headlessui/vue'
const { modal } = useAppStore()
onBeforeMount(() => modal.stack = true)
onBeforeUnmount(() => modal.stack = false)
</script>
