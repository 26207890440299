import { ref } from 'vue'
import { defineStore } from 'pinia'
import { graphql } from '@/services/shopify'
import { useAppStore } from '@/stores'
import { useAuthStore } from './auth'
import { useCustomerStore as useRechargeCustomerStore } from '@/stores/recharge'

import { GetCustomer, type GetCustomerQuery } from '@/graphql/queries'
import { CustomerUpdate, CustomerRecover, type CustomerUpdateQuery, type CustomerRecoverQuery } from '@/graphql/mutations'

import type { Customer, CustomerEdit, CustomerFields, CustomerErrors } from '@/types/shopify'

export const useCustomerStore = defineStore('shopify/customer', () => {

  const { alert, handleError } = useAppStore()
  const auth = useAuthStore()
  const rechargeCustomerStore = useRechargeCustomerStore()

  const loading = ref(false)
  const customer = ref<Customer>()

  const loadCustomer = async () => {
    try {
      loading.value = true
      const { data } = await graphql.query<GetCustomerQuery>({
        query: GetCustomer,
        variables: {
          token: auth.accessToken
        }
      })
      customer.value = {
        ...data.customer,
        nid: gidToId(data.customer.id)
      }
      loading.value = false
      return customer.value
    } catch (e) {
      loading.value = false
      customer.value = undefined
      sendToSentry(e)
    }
  }

  const updateCustomer = async (input: CustomerEdit) => {
    const originalCustomer = { ...customer.value }
    const errors: CustomerErrors = {}
    try {
      const {
        data: { customerUpdate }
      } = await graphql.query<CustomerUpdateQuery>({
        query: CustomerUpdate,
        variables: {
          customerAccessToken: auth.accessToken,
          input
        }
      })
      if (customerUpdate.customer) {
        if (input.email && originalCustomer.email !== input.email) {
          await rechargeCustomerStore.updateCustomerEmail(input.email)
        }
        customer.value = customerUpdate.customer
      } else {
        const fields: CustomerFields[] = [ 'firstName', 'lastName', 'email', 'password', 'phone' ]
        for (const field of fields) {
          const error = customerUpdate.customerUserErrors.find(x => x.field?.includes(field))
          if (error) {
            errors[field] = error.message
          }
        }
      }
    } catch (e) {
      handleError(e)
    }

    return { errors }
  }

  const recoverCustomer = async (email: string) => {
    try {
      const {
        data: { customerRecover }
      } = await graphql.query<CustomerRecoverQuery>({
        query: CustomerRecover,
        variables: {
          email
        }
      })
      if (customerRecover) {
        alert('Check your inbox for a password recovery email.')
      } else {
        alert('Something doesn\'t look right, please try again.', 'error')
      }
    } catch (e) {
      handleError(e)
    }
  }

  const tagCustomer = async (tags: string | string[]) => {
    if (!customer.value) {
      throw new Error('Cannot tag customer')
    }
    try {
      const response = await fetch('/api/customer/tag', {
        method: 'PUT',
        headers: useCustomerAuthHeaders(),
        body: JSON.stringify({ tags: Array(tags).flat() })
      })
      const data = await response.json() as { tags: string[] }
      customer.value = {
        ...customer.value,
        tags: data.tags
      }
    } catch (e) {
      sendToSentry(e)
    }
  }

  const useCustomer = () => {
    if (!customer.value && !loading.value) {
      loadCustomer()
    }
    return customer
  }

  const useCustomerAuthHeaders = () => {
    return {
      'content-type': 'application/json',
      'x-customer-id': String(customer.value?.id),
      'authorization': `Bearer ${auth.accessToken}`
    }
  }

  const reset = () => {
    customer.value = undefined
    loading.value = false
  }

  return {
    loading,
    customer,
    loadCustomer,
    useCustomer,
    updateCustomer,
    recoverCustomer,
    tagCustomer,
    useCustomerAuthHeaders,
    reset
  }

})
