<template>
  <LoaderButton
    v-if="needRefresh"
    :loading="loading"
    class="fixed right-2 left-2 bottom-2 xs:left-auto button shadow-sm flex-center bg-yellow-200 border-yellow-300 z-[1000]"
    @click="reload"
  >
    A new version is available. Click to reload.
  </LoaderButton>
</template>

<script setup lang="ts">
const { $pwa: { needRefresh, updateServiceWorker } } = useNuxtApp()
const loading = ref(false)
const reload = () => {
  loading.value = true
  updateServiceWorker(true)
  setTimeout(() => {
    window.location.reload()
  }, 5000)
}
</script>
