declare global {
  interface Window {
    addGTMLogger: (els: Element[] | Element | Node[] | undefined) => void
  }
}

const logGTM = (element: Element) => {
  const trigger = element.closest('[data-gtm-action]')
  if (trigger) {
    const path = window.location.pathname.replace(/^\//, '')
    const category = trigger.getAttribute('data-gtm-category') || path
    const action = trigger.getAttribute('data-gtm-action')
    const label = trigger.getAttribute('data-gtm-label') || path
    const msg = Object.entries({ category, action, label }).filter(([ _, value ]) => value).map(([ key, value ]) => `${key}: ${value}`)
    console.log('[GTM]', msg.join(', '))
  }
}

const handleGTMClick = (event: Event) => {
  if (event instanceof PointerEvent && event.altKey) {
    event.preventDefault()
    event.stopPropagation()
  }
  if (event.target instanceof Element) {
    logGTM(event.target)
  }
}

export const addGTMLogger = (els: Element[] | Element | Node[] | undefined) => {
  let elements = els || [ window ]
  if (elements instanceof NodeList) {
    elements = Array.from(elements)
  } else if (!Array.isArray(elements)) {
    elements = [ elements ]
  }
  elements.forEach(el => {
    el.removeEventListener('click', handleGTMClick)
    el.addEventListener('click', handleGTMClick)
  })
}

export const initGTM = (element: Element | undefined = undefined) => {
  if (import.meta.env.VITE_DEV) {
    console.log('gtm logger added')
    addGTMLogger(element)
  }
  window.addGTMLogger = addGTMLogger
}
