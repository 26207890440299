import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { isFuture, isPast, isValid } from 'date-fns'

import { graphql } from '@/services/shopify'
import { edgesToNodes, graphqlUnpackProduct } from '@/utils/graphql'
import { mapMetaFields } from '@/utils/shopify'
import { GetPortalSettings, type GetPortalSettingsQuery } from '@/graphql/queries'
import type { Product, PortalBanner, Metafield } from '@/types/shopify'

const isAddon = (product: Product | undefined | null): product is Product => product !== null && product !== undefined

const parseBoolean = (field: Metafield) => field?.value !== 'false'

export const useSettingsStore = defineStore('settings', () => {
  const loading = ref(false)
  const features = ref({
    favorites: false,
    referrals: false,
    coffee: false
  })
  const blenderBottle = ref<Product>()
  const shakerBottle = ref<Product>()
  const spoon = ref<Product>()
  // const bannerAutoplay = ref<boolean>(true)
  const banners = ref<PortalBanner[]>([])
  const bannerPagination = computed(() => banners.value.length > 1)

  const addons = computed(() => banners.value.filter(i => i.product).map(i => i.product).filter(isAddon))

  const getSettings = async () => {
    loading.value = true
    const { data } = await graphql.query<GetPortalSettingsQuery>({
      query: GetPortalSettings
    })
    blenderBottle.value = graphqlUnpackProduct(data.blenderBottle)
    shakerBottle.value = graphqlUnpackProduct(data.shakerBottle)
    spoon.value = graphqlUnpackProduct(data.spoon)
    features.value = {
      favorites: parseBoolean(data.settings?.enableFavorites),
      referrals: parseBoolean(data.settings?.enableReferrals),
      coffee: parseBoolean(data.settings?.enableCoffee)
    }
    // bannerAutoplay.value = parseBoolean(data.settings?.bannerAutoplay)
    banners.value = filterBanners(edgesToNodes(data.settings.banners?.references?.edges || []).map(i => {
      const fields = mapMetaFields(i.fields)
      // @ts-ignore
      fields.product = graphqlUnpackProduct(fields.product?.reference)
      return fields
    }) as PortalBanner[])
    loading.value = false
  }

  const filterBanners = (banners: PortalBanner[]) => {
    return banners.filter(i => {
      const showAt = new Date(i.show_at?.value || '')
      const hideAt = new Date(i.hide_at?.value || '')

      if (isValid(showAt) && isValid(hideAt)) {
        return isPast(showAt) && isFuture(hideAt)
      }
      if (isValid(showAt)) {
        return isPast(showAt)
      }
      if (isValid(hideAt)) {
        return isFuture(hideAt)
      }

      return true
    })
  }

  const reset = () => {
    loading.value = false
    // no need to reset non-user specific data
    // blenderBottle / banners
  }

  return {
    getSettings,
    features,
    blenderBottle,
    shakerBottle,
    spoon,
    // bannerAutoplay,
    banners,
    bannerPagination,
    addons,
    reset
  }
})
