import type { ProductEdge, Image, Product, ProductVariant, GraphqlProduct } from '@/types/shopify'

export interface GraphqlNode {
  id?: string
  nid?: number
}

export interface GraphqlEdge<T> {
  cursor?: string
  node: T
}

export const imageRef = (meta: any) => meta?.reference?.image

export const gidToId = (gid: string) => Number(gid.split('/').pop()?.split('?')[0])

export const edgesToNodes = <T>(edges: GraphqlEdge<T>[]) => edges?.map(i => i.node)

export const bundleProductKey = (meta: any) => {
  const { id, variants } = meta?.reference || {}
  const variant = variants?.edges?.[0]?.node || {}
  const { id: variantId } = variant
  return `${gidToId(id)}/${gidToId(variantId)}`
}

export const graphqlUnpack = <T extends GraphqlNode>(edges: GraphqlEdge<T>[]) => {
  return edgesToNodes<T>(edges)?.map(i => {
    if ('id' in i && typeof i.id == 'string') {
      i.nid = gidToId(i.id)
    }
    return i
  })
}

export const graphqlUnpackProducts = (edges: GraphqlEdge<ProductEdge>[]) => (
  graphqlUnpack(edges).map(product => graphqlUnpackProduct(product))
)

export const graphqlUnpackProduct = (product: ProductEdge | undefined) => {
  return product && {
    ...product,
    nid: gidToId(product.id),
    variants: graphqlUnpack<ProductVariant>(product.variants.edges),
    images: graphqlUnpack<Image>(product.images.edges)
  } as Product
}

export const unpackProduct = (rawProduct: any) => {
  const product: GraphqlProduct = pick(rawProduct, 'id', 'handle', 'title', 'tags', 'description', 'descriptionHtml', 'createdAt', 'updatedAt', 'publishedAt', 'seo')
  const {
    bundleProduct,
    nutritionImage,
    nutritionFlavorForwardImage,
    zoomImage,
    profileImage,
    flavorForwardImage
  } = pick(rawProduct, 'bundleProduct', 'shortTitle', 'nutritionImage', 'nutritionFlavorForwardImage', 'zoomImage', 'profileImage', 'flavorForwardImage', 'bgColor')
  product.nid = gidToId(product.id)
  if (bundleProduct) {
    product.key = bundleProductKey(bundleProduct)
  }
  product.ratingAverage = Number(rawProduct.ratingAverage?.value || 0)
  product.ratingCount = Number(rawProduct.ratingCount?.value || 0)
  product.calories = Number(rawProduct.calories?.value || 0)
  product.protein = rawProduct.protein?.value || ''
  product.sugar = rawProduct.sugar?.value || ''
  product.shortTitle = rawProduct.shortTitle?.value || ''
  product.bgColor = rawProduct.bgColor?.value || '#000000'
  product.nutrition = rawProduct.nutrition?.value || ''
  product.nutritionImage = imageRef(nutritionImage)
  product.nutritionFlavorForwardImage = imageRef(nutritionFlavorForwardImage)
  product.zoomImage = imageRef(zoomImage)
  product.profileImage = imageRef(profileImage)
  product.flavorForwardImage = imageRef(flavorForwardImage)
  product.images = graphqlUnpack(rawProduct.images?.edges)
  return product
}

export const unpackListProduct = (rawProducts: any) => {
  return graphqlUnpack<any>(rawProducts).map(rawProduct => {
    return unpackProduct(rawProduct)
  })
}

export const unpackVarietyPack = (rawProduct: any) => {
  // @ts-ignore
  const product: GraphqlVarietyPack = pick(rawProduct, 'id', 'handle', 'title', 'tags', 'description', 'descriptionHtml', 'createdAt', 'updatedAt', 'publishedAt', 'seo')
  const {
    zoomImage,
    profileImage,
    flavorForwardImage
  } = pick(rawProduct, 'zoomImage', 'profileImage', 'flavorForwardImage')
  product.nid = gidToId(product.id)
  product.ratingAverage = Number(rawProduct.ratingAverage?.value || 0)
  product.ratingCount = Number(rawProduct.ratingCount?.value || 0)
  product.zoomImage = imageRef(zoomImage)
  product.profileImage = imageRef(profileImage)
  product.flavorForwardImage = imageRef(flavorForwardImage)
  product.images = graphqlUnpack(rawProduct.images?.edges)
  product.flavors = unpackListProduct(rawProduct.flavors?.references?.edges)
  product.quantityMap = JSON.parse(rawProduct.quantityMap?.value || '{}')
  return product
}

export const unpackVarietyPacks = (rawCollection: any) => {
  return graphqlUnpack<any>(rawCollection.products.edges).map(rawProduct => {
    return unpackVarietyPack(rawProduct)
  })
}