const breakpoint = (size: string) => {
  const mq = window.matchMedia(`(max-width: ${size})`)
  const breakpoint = ref(mq.matches)
  mq.addEventListener('change', () => breakpoint.value = mq.matches)
  return breakpoint
}

export const breakpoints = {
  'xs': breakpoint('414px'),
  'sm': breakpoint('640px'),
  'md': breakpoint('768px'),
  'lg': breakpoint('1024px'),
  'xl': breakpoint('1280px'),
  '2xl': breakpoint('1536px')
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      breakpoints
    }
  }
})
