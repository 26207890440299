const RECHARGE_DOMAIN = `https://${SHOPIFY_STORE}-sp.admin.rechargeapps.com`

export const shopifyAdminUrl = (path: string) => `https://admin.shopify.com/store/${SHOPIFY_STORE}/${path}`
export const shopifyCustomerUrl = (gid: string) => shopifyAdminUrl(`customers/${gidToId(gid)}`)

export const rechargeAdminUrl = (path: string) => `${RECHARGE_DOMAIN}/merchant/${path}`
export const rechargeCustomerUrl = (id: number) => rechargeAdminUrl(`customers/${id}`)

export const shopifyReferralUrl = (referral: string) => `https://${WEBSITE_DOMAIN}/r/${referral}`
export const shopifyMultipassUrl = (token: string, returnTo = '') => {
  const { public: { storefrontDomain } } = useRuntimeConfig()
  const url = new URL(`https://${storefrontDomain}/account/login/multipass/${token}`)
  if (returnTo) {
    url.searchParams.append('return_to', returnTo)
  }
  return url.toString()
}
