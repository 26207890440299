<template>
  <div
    data-testid="section-loader"
    class="flex items-center justify-center p-5"
  >
    <SvgIcon
      name="loader"
      class="animate-spin w-10 h-10 text-purple-100"
    />
  </div>
</template>
