import type { FetchOptions } from 'ofetch'

interface RetryOptions {
  retries?: number
  delay?: number
  /** If true all retries will be reported to sentry */
  logAllErrors?: boolean
}
export const fetchWithRetry = async <T>(promiseFn: () => Promise<T>, options: RetryOptions = {}): Promise<T> => {
  const { retries = 2, delay = 2000, logAllErrors = false } = options
  try {
    return await promiseFn()
  } catch (error) {
    await new Promise(resolve => setTimeout(resolve, delay))
    if (retries > 0) {
      if (logAllErrors) {
        sendToSentry(error)
      }
      return await fetchWithRetry(promiseFn, { ...options, retries: retries - 1 })
    }
    sendToSentry(error)
    return await Promise.reject(error)
  }
}

const adminFetchOpts: FetchOptions = {
  retry: 1,
  retryStatusCodes: [ 401 ],
  onRequest (context) {
    const { jwt } = useAdminStore()
    if (jwt) {
      context.options.headers = {
        ...context.options.headers,
        authorization: `bearer ${jwt}`
      }
    }
  },
  async onResponseError (context) {
    const admin = useAdminStore()
    const { _data, status } = context.response
    if (admin.jwt && status === 401) {
      if (_data?.statusMessage === 'expired') {
        const newToken = await $fetch('/api/auth/admin/refresh-session', {
          headers: {
            authorization: `bearer ${admin.jwt}`
          }
        })
        if (typeof newToken === 'string') {
          admin.jwt = newToken
        }
      }
    }
  }
}

export const adminFetch = $fetch.create(adminFetchOpts)
