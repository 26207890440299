<template>
  <WelcomeSlide>
    <template #image>
      <img
        src="@/assets/welcome/welcome-four.webp"
        alt="Oats Overnight"
        width="366"
        height="384"
        class="absolute translate-y-28"
      >
    </template>
    <template #title>
      Rate Flavors & Leave Notes
    </template>
    <template #paragraph>
      Pick your favorites. You can even leave notes in case there's something specific you want to remember.
    </template>
  </WelcomeSlide>
</template>
